@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@300;400;500&family=Montserrat:wght@500;700&display=swap");

$primaryColor: hsl(225, 50%, 48%);
$secondaryColor: hsl(180, 17%, 95%);
$colorOne: hsl(15, 74%, 70%);
$colorTwo: hsl(68, 85%, 35%);
$hoverColor: hsl(225, 56%, 59%);
$whiteColor: hsl(0, 0%, 100%);
$blackColor: hsl(0, 0%, 12%);
$textColor: hsl(240, 1%, 48%);
$borderColor: hsl(0, 0%, 83%);
$whiteColorDim: hsl(0, 0%, 93%);
$grayBg: hsl(0, 0%, 96%);
$grayText: rgb(145, 145, 145);
$inputColor: hsl(330, 12%, 97%);
$boxShadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
  rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
$transition: 0.4s ease;

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Spartan", sans-serif;
}

body {
  background: $whiteColor;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
  cursor: pointer;
}

.section {
  padding: 4rem 0 2rem;
}

.container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.icon {
  font-size: 1.3rem;
  cursor: pointer;
}

.flex {
  display: flex;
  align-items: center;
}

.grid {
  display: grid;
  align-items: center;
}

.none {
  display: none;
}

.btn {
  padding: 0.7rem 1.5rem;
  background: $primaryColor;
  border: none;
  outline: none;
  border-radius: 3rem;
  cursor: pointer;
  color: $whiteColor;

  a {
    color: $whiteColor;
    font-weight: 500;
  }

  &:hover {
    background: $hoverColor;
  }
}

img,
video {
  width: 100%;
  height: auto;
}

input {
  font-size: 100%;
  color: $grayText;
}

p {
  font-size: 13px;
  color: $grayText;
  line-height: 22px;
}

h4 {
  padding: 1rem 0;
  font-weight: 700;
  color: $blackColor;
}

h2 {
  font-size: 25px;
  padding: 1rem 0;
  color: $blackColor;
}

// navbar
.navBar {
  position: fixed;
  flex-direction: column;
  width: 100%;
  z-index: 1000;

  .navBarOne,
  .navBarTwo {
    justify-content: space-between;
    padding: 1rem;
    width: 100%;
  }

  .navBarOne {
    background: $secondaryColor;

    .icon:hover {
      color: $primaryColor;
    }

    .atb {
      gap: 1rem;

      span {
        color: $blackColor;
        font-size: 13px;
        cursor: pointer;

        &:hover {
          color: $primaryColor;
          font-size: 700;
        }
      }
    }
  }

  .navBarTwo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 3rem;
    padding: 1.5rem 1rem;
    position: fixed;
    transition: 0.3s ease-in-out;

    .logoDiv {
      .logo {
        width: 20%;
      }
    }

    @media screen and (max-width: 768px) {
      .navBarMenu {
        position: absolute;
        background: $secondaryColor;
        padding: 1.5rem;
        border-radius: 10px;
        top: 5rem;
        right: -50%;
        width: 80%;
        transform: translateX(50%);
        z-index: 2000;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
          rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
        border: 2px solid $whiteColor;
        transition: 0.4s ease-in-out;

        .menu {
          flex-direction: column;
          gap: 0.5rem;
          text-align: center;

          .listItem {
            padding: 0.5rem 0;
            cursor: pointer;
            color: $blackColor;

            &:hover {
              color: $primaryColor;
              font-weight: 700;
            }
          }
        }

        .btnOne {
          display: block;
          justify-content: center;
          margin: auto;
          margin-top: 1rem;
        }
      }

      .btnTwo {
        display: none;
      }
    }

    .toggleIcon {
      .icon {
        font-size: 30px;
        color: $blackColor;
      }
    }

    .showNavBar {
      right: 50%;
      transition: 0.5s ease-in-out;
    }
  }
  .navbar_With_Bg {
    background: $secondaryColor;
    padding: 0.5rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
  }
}

// home
.home {
  padding-top: 10rem;
  text-align: center;
  gap: 2rem;
  flex-direction: column;

  .mainText {
    color: $blackColor;
    font-weight: 700;
    font-size: 15px;
    line-height: 2.5rem;
  }

  .homeImages {
    width: 100%;
    margin: 1rem auto 0;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5rem;

    .videoDiv {
      width: 100%;

      .video {
        border-radius: 10rem;
      }
    }

    .plane {
      position: absolute;
      width: 100%;
      top: -4%;
    }
  }
}

// Search
.search {
  .sectionContainer {
    width: 100%;
    padding: 1.5rem;
    border: 2px solid $grayBg;
    border-radius: 16px;
    row-gap: 2rem;

    .btns {
      background: $secondaryColor;
      padding: 0.5rem;
      border-radius: 1rem;
      flex-direction: column;
      margin: auto;
      width: 100%;

      .singleBtn {
        padding: 0.8rem 1.5rem;
        font-size: 13px;
        border-radius: 3rem;
        width: 80%;
        text-align: center;
        cursor: pointer;

        &:hover {
          background: $primaryColor;
          color: $whiteColor;
          font-weight: 500;
          transition: 0.4s ease;
        }
      }

      .active {
        background: $primaryColor;
        color: $whiteColor;
        font-weight: 500;
      }
    }

    .searchInputs {
      width: 100%;
      justify-content: center;
      flex-direction: column;
      gap: 1.5rem;
      margin-top: 1.5rem;
      cursor: pointer;

      .singleInput {
        .iconDiv {
          padding: 16px 10px;
          border-radius: 3rem;
          background: $secondaryColor;
          margin-right: 1rem;

          .icon {
            color: $blackColor;
            opacity: 0.7;
          }
        }

        .texts {
          h4 {
            padding-top: 0;
            padding-bottom: 0.7rem;
            color: $blackColor;
            font-weight: 700;
          }

          input {
            width: 95%;
            border: none;
            outline: none;
            background: transparent;

            &::placeholder {
              font-size: 12px;
              opacity: 0.5s;
            }
          }
        }
      }
      .btnBlock {
        justify-content: center;
      }
    }
  }
}

// Support
.support {
  .sectionContainer {
    .titlesDiv {
      text-align: center;
      small {
        letter-spacing: 0.8rem;
        text-transform: uppercase;
        font-size: 13px;
        color: $blackColor;
        line-height: 1.5rem;
      }
    }
    .infoDiv {
      margin-top: 2rem;
      gap: 1.5rem;
      .textDiv {
        gap: 1.5rem;
        .singleInfo {
          .number {
            background: $primaryColor;
            padding: 5px 12px;
            width: 40px;
            border-radius: 3rem;
            text-align: center;
            font-size: 13px;
            color: $whiteColor;
            font-weight: 700;
          }
          .colorTwo {
            background: $colorTwo;
          }
          .colorOne {
            background: $colorOne;
          }
        }
      }
      .imgDiv {
        margin: auto;
        img {
          width: 100%;
          max-width: 420px;
        }
      }
    }
  }
}

.info {
  background: $secondaryColor;
  .infoContainer {
    flex-direction: column;
    text-align: center;
    grid-area: 1.5rem;
    justify-content: space-between;
  }
  .cardsDiv {
    gap: 1.5rem;
    margin-top: 3rem;
    .singleCard {
      background: $whiteColor;
      padding: 3rem 2rem;
      border-radius: 4rem;
      gap: 1rem;
      text-align: center;
      margin: auto;
      height: 320px;
      width: 90%;
      max-width: 250px;
      .iconDiv {
        justify-content: center;
        margin: auto;
        background: $primaryColor;
        width: max-content;
        height: 55px;
        padding: 1rem;
        border-radius: 3rem;
        .icon {
          color: $whiteColor;
        }
      }
      .cardTitle {
        color: $blackColor;
        font-weight: 700;
      }
      .colorOne {
        background: $colorOne;
      }
      .colorTwo {
        background: $colorTwo;
      }
    }
  }
}

// Lounge
.lounge {
  margin-top: 2rem;
  .sectionContainer {
    gap: 1.5rem;
    .imgDiv {
      margin: auto;
      img {
        width: 100%;
        max-width: 430px;
      }
    }
    .textDiv {
      .grids {
        margin-top: 1rem;
        grid-auto-flow: 1.5rem;
        .singleGrid {
          .gridTitle {
            display: block;
            padding-bottom: 0.5rem;
            font-weight: 500;
            color: $blackColor;
          }
        }
      }
    }
  }
}

// Travellers
.travelers {
  .sectionContainer {
    text-align: center;
    .travelersContainer {
      margin-top: 1rem;
      gap: 1.5rem;
      .singleTraveler {
        position: relative;
        height: 320px;
        width: 85%;
        max-width: 250px;
        overflow: hidden;
        border-radius: 2rem;
        gap: 1rem;
        margin: auto;
        .destinationImage,
        .travelerImage,
        .travelerName {
          position: absolute;
        }
        .destinationImage {
          height: 100%;
          width: 100%;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          object-fit: cover;
        }
        .travelerImage {
          height: 50px;
          width: 50px;
          object-fit: cover;
          border-radius: 50%;
          bottom: 20px;
          left: 70%;
          transform: translateX(-50%);
          border: 3px solid rgba(0, 0, 0, 0.6);
          transition: $transition;
          animation: animated 2.3s infinite;
        }
        @keyframes animated {
          0% {
            transform: translateY(0px);
          }
          50% {
            transform: translateY(8px);
          }
          100% {
            transform: translateY(0px);
          }
        }
        .travelerName {
          bottom: -100%;
          width: 100%;
          height: max-content;
          background: $whiteColor;
          padding: 1.5rem 1rem;
          transition: $transition;
          animation: animatedBorder 2.3s infinite;

          span {
            display: block;
            color: black;
            font-weight: 700;
          }
          p {
            color: $primaryColor;
          }
        }
        @keyframes animatedBorder {
          0% {
            border-radius: 0rem;
          }
          50% {
            border-radius: 2rem;
          }
          100% {
            border-radius: 0rem;
          }
        }
        &:hover {
          .travelerName {
            bottom: 0;
            transition: 0.4s ease-in-out;
          }
          .travelerImage {
            bottom: 85px;
            transition: 0.4s ease-in-out;
            z-index: 1;
          }
        }
      }
    }
  }
}

// Subscribe
.subscribe {
  background: $secondaryColor;
  .sectionContainer {
    text-align: center;
    .inputDiv {
      margin-top: 1rem;
      flex-direction: column;
      background: $whiteColor;
      border-radius: 1rem;
      input {
        border: none;
        outline: none;
        background: transparent;
        padding: 1rem 0.5rem;

        &::placeholder {
          font-size: 13px;
          opacity: 0.5;
        }
      }
      .btn {
        width: 100%;
      }
    }
  }
}

// Footer
.footer {
  background: $secondaryColor;
  padding-bottom: 5rem;

  .sectionContainer {
    gap: 2.5rem;
    .gridOne {
      gap: 1rem;
      justify-content: center;
      margin: auto;

      .logoDiv {
        .Logo {
          width: 20%;
        }
      }
      .socialIcon {
        margin-top: 1rem;
        gap: 1rem;
        .icon {
          height: 40px;
          width: 40px;
          border-radius: 10rem;
          border: 1px solid $borderColor;
          box-shadow: $boxShadow;
          padding: 0.4rem;
          color: $grayText;
          &:hover {
            background: $primaryColor;
            color: $whiteColor;
            transition: $transition;
          }
        }
      }
    }
    .footerLinks {
      display: grid;
      .linkTitle {
        color: $blackColor;
        font-weight: 500;
        display: block;
        padding-bottom: 1rem;
      }
      li a {
        display: block;
        color: $grayText;
        transition: $transition;
        padding: 0.3rem 0;
        &:hover {
          color: $primaryColor;
          transform: translateX(7px);
        }
      }
    }
  }
}

// Adding media queries
@media screen and (min-width: 480px) {
  // Home
  .home {
    padding-top: 12rem;
  }
  // Search
  .search {
    .sectionContainer {
      .searchInputs {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  // Travel
  .travelers {
    .sectionContainer {
      .travelersContainer {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  // Subscribe
  .subscribe {
    .sectionContainer {
      max-width: 80%;
      margin: auto;
      .inputDiv {
        flex-direction: row;
        padding: 0 0.5rem;
        border-radius: 3rem;
      }
    }
  }
  // Footer
  .footer {
    .sectionContainer {
      align-items: flex-start;
      grid-template-columns: repeat(2, 1fr);
      .footerLinks {
        justify-content: center;
      }
    }
  }
}

@media screen and (min-width: 570px) {
  // Home
  .home {
    .mainText {
      width: 80%;
    }
    .homeImages {
      .video {
        border-radius: 15rem;
      }
      .plane {
        position: absolute;
        width: 95%;
        top: -8%;
      }
    }
  }
  // Search
  .search {
    .sectionContainer {
      .btns {
        border-radius: 3rem;
        flex-direction: row;
      }
    }
  }
  // Support
  .support {
    .sectionContainer {
      .infoDiv {
        .textDiv {
          margin-top: 2rem;
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  // Info
  .info {
    .infoContainer {
      .titleDiv {
        flex-direction: row;
        h2 {
          text-align: left;
          max-width: 500px;
        }
      }
      .cardsDiv {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
  // Lounge
  .lounge {
    .sectionContainer {
      .textDiv {
        .grids {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  // Subscriber
  .subscribe {
    .sectionContainer {
      .inputDiv {
        justify-content: space-between;
        .btn {
          max-width: max-content;
        }
      }
    }
  }
  // Footer
  .footer {
    .sectionContainer {
      padding-top: 2rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

@media screen and (min-width: 680px) {
  // Home
  .home {
    .mainText {
      width: 80%;
    }
    .homeImages {
      .videoDiv {
        width: 80%;
        .video {
          height: 100%;
          width: 100%;
          border-radius: 15rem;
        }
      }
      .plane {
        width: 95%;
        top: -8%;
      }
    }
  }
  // Search
  .search {
    .sectionContainer {
      .btns {
        border-radius: 3rem;
        flex-direction: row;
      }
      .searchInputs {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  // Footer
  .footer {
    .sectionContainer {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media screen and (min-width: 768px) {
  // Navbar
  .navBar {
    align-items: center;
    .navBarOne {
      .none {
        display: flex;
        gap: 1.5rem;

        li {
          font-size: 13px;
          gap: 0.5rem;
          color: $textColor;
          &:hover {
            color: $primaryColor;
            text-decoration: underline;
          }
        }
      }
    }
    .navBarTwo {
      justify-content: space-between;
      align-items: center;
      .logoDiv {
        .logo {
          max-width: 20%;
        }
      }
      .menu {
        flex-direction: row;
        gap: 0.5rem;
        .listItem {
          padding: 0 0.5rem;
          font-size: 14px;
          cursor: pointer;
          color: $textColor;
          font-weight: 700;
          &:hover {
            color: $primaryColor;
            font-weight: 700;
          }
        }
      }
      .btnOne,
      .toggleIcon {
        display: none;
      }
    }
  }
  // Search
  .search {
    .sectionContainer {
      .btns {
        width: 70%;
        border-radius: 3rem;
        flex-direction: row;
      }
      .searchInputs {
        grid-template-columns: repeat(4, 1fr);
        .singleInput {
          .texts {
            input {
              width: 100%;
            }
          }
        }
      }
    }
  }
  // Support
  .support {
    .sectionContainer {
      .infoDiv {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
        margin-top: 5rem;
        .textDiv {
          margin-top: 0rem;
          grid-template-columns: repeat(1, 1fr);
        }
      }
    }
  }
  // Info
  .info {
    .infoContainer {
      padding: 0 6rem;
      .titleDiv {
        width: 90%;
        margin: auto;
        justify-content: space-between;
        align-items: center;
      }
      .cardsDiv {
        grid-template-columns: repeat(3, 1fr);
        .singleBtn {
          max-width: 260px;
          padding: 3rem 0.5rem;
        }
      }
    }
  }
  // Lounge
  .lounge {
    .sectionContainer {
      grid-template-columns: repeat(2, 1fr);
      .textDiv {
        grid-area: 1;
        .grids {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }
  // Travelers
  .travelers {
    .sectionContainer {
      .travelersContainer {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
}

@media screen and (min-width: 960px) {
  .home {
    .mainText {
      width: 70%;
      padding-bottom: 1.5rem;
      font-size: 22px;
      line-height: 4rem;
    }
  }
  // Search
  .search {
    .sectionContainer {
      .searchInputs {
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
  // Traveler
  .travelers {
    .sectionContainer {
      .travelersContainer {
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 3rem 0;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  p {
    font-size: 15px;
    line-height: 25px;
  }
  h4 {
    font-size: 20px;
  }
  h2 {
    font-size: 27px;
  }
  .section {
    padding: 6rem 0 4rem;
  }
  .container {
    width: 77%;
    margin: auto;
  }
  .navBar {
    .navBarOne,
    .navBarTwo {
      padding: 1.5rem 5rem;
    }
    .navbar_With_Bg {
      padding: 0 5rem;
    }
  }
  .home {
    .mainText {
      width: 60%;
      font-size: 25px;
      line-height: 4rem;
    }
    .homeImages {
      .videoDiv {
        width: 90%;
        height: 300px;
        .video {
          height: 100%;
          width: 100%;
          object-fit: cover;
          border-radius: 15rem;
        }
      }
      .plane {
        width: 100%;
        top: -10%;
      }
    }
  }
  .search {
    .sectionContainer {
      .btns {
        width: 50%;
      }
    }
  }
}

@media screen and (min-width: 1106px) {
  .subscribe {
    .sectionContainer {
      max-width: 60%;
    }
  }
}

@media screen and (min-width: 1332px) {
  .navBar {
    .navBarTwo {
      .menu {
        gap: 1rem;
        .listItem {
          padding: 0 1.5rem;
          font-size: 14px;
        }
      }
    }
  }
  .support {
    .sectionContainer {
      .infoDiv {
        gap: 1rem;
        .textDiv {
          .singleInfo {
            p {
              max-width: 500px;
            }
          }
        }
      }
    }
  }
  .subscribe {
    .sectionContainer {
      max-width: 50%;
    }
  }
}

@media screen and (min-width: 1440px) {
  .home {
    .homeImages {
      .plane {
        width: 90%;
        top: -30%;
      }
    }
  }
}

::selection {
  background: $primaryColor;
  color: $whiteColor;
}

::-webkit-scrollbar {
  width: 10px;
  background: $grayBg;
}

::-webkit-scrollbar-thumb {
  background: $primaryColor;
  border-radius: 10px;
}
